import ToastComponent from './Toast.vue'

const Toast = {}

Toast.install = (Vue, options) => {
  const ToastConstructor = Vue.extend(ToastComponent)
  const instance = new ToastConstructor() // 创建Toast实例
  instance.i18n = options.i18n
  instance.store = options.store
  instance.$mount(document.createElement('div')) // 挂载到DOM上
  const parentElem = (options && options.elem) || document.body
  parentElem.appendChild(instance.$el)

  /**
   * @methods  提示框
   * @param {Object} options 提示参数
   * @param {Function} callback 确认回调参数
   * @param {Function} cancel 取消回调参数
   */
  Vue.prototype.$toast = ({
    msg = '',
    duration = 2000,
    isConfirm = false,
    dontHide = false,
  }) => {
    return new Promise((resolve, reject) => {
      instance.msg = msg
      instance.show = true
      instance.isConfirm = isConfirm
      instance.confirm = () => {
        instance.show = false
        resolve()
      }
      instance.cancel = () => {
        instance.show = false
        reject()
      }
      if (!isConfirm && !dontHide) {
        setTimeout(() => {
          instance.show = false
          resolve()
        }, duration)
      }
    })
  }
}

export default Toast
