import Vue from 'vue'
import App from './App.vue'
import VueI18n from 'vue-i18n'
import Toast from '@/components/Toast/index'
import { Loading, Dialog, Select, Option, OptionGroup, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'
import httpCreater from '@/util/http'
import { mapState } from 'vuex'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: 'https://fcdc8fd9798b4d0a99b704bedb7b0eb0@o201635.ingest.sentry.io/5842752',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

Vue.use(Select)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Dialog)
Vue.use(VueI18n)
Vue.use(Loading)

const i18n = new VueI18n({
  locale: store.state.lang, // 语言标识
  fallbackLocale: 'en',
  silentFallbackWarn: true,
  // this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages: {
    zh: require('./lang/zh'), // 中文语言包
    'zh-CN': require('./lang/zh'), // 中文语言包
    en: require('./lang/en'), // 英文语言包
  },
})

Vue.use(Toast, {
  i18n,
  store,
})

Vue.mixin({
  computed: {
    ...mapState({
      lang: state => state.lang,
    }),
  },
})

Object.defineProperty(Vue.prototype, '$locale', {
  get: function () {
    return i18n.locale
  },
  set: function (locale) {
    i18n.locale = locale
  },
})

httpCreater(store, router, (message, res) => {
  Vue.prototype.$toast({
    msg: message,
  })
}).then((http) => {
  Vue.prototype.$http = http

  new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
}).catch((err) => {
  console.log(err)
})
