<template>
  <div class="cookie" v-if="show">
    <div class="cookie-text">
      This website uses cookies to ensure you get the best experience on our website <router-link class="link" to="/privacy">More info</router-link>
    </div>
    <div class="btn" @click="gotIt">Got it!</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: true,
    }
  },
  mounted() {
    const usecookie = localStorage.getItem('use-cookie')
    if (usecookie) {
      this.show = false
    }
  },
  methods: {
    gotIt() {
      this.show = false
      window.localStorage.setItem('use-cookie', '1')
    },
  },
}
</script>
<style lang="scss" scoped>
.cookie{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 40px;
  padding: 0 50px;
  box-sizing: border-box;
  background: #fff;
  color: #5f5e5e;
  box-shadow: 0 -1px 4px rgba(0,0,0,0.2);
  .link{
    color: #48B2FF;
  }
  .btn{
    background: #F5A255;
    color: #fff;
    height: 26px;
    line-height: 26px;
    text-align: center;
    width: 60px;
    border-radius: 9px;
    cursor: pointer;
  }
}
</style>
