<template>
  <div>
    <el-dialog :show-close="false" custom-class="g_el-dialog-wrapper" :visible.sync="dialogShow">
      <div class="dialog">
        <h2 class="dialog-title">
          Choose a browser extension
        </h2>
        <p class="dialog-sub">
          More browser will be supported latter
        </p>
        <div class="dialog-list">
          <a href="https://chrome.google.com/webstore/detail/little-star-github-stars/kmjfdonmflchjdlmeoecbmebfpnafpec" class="item"><img class="item-pic" src="../assets/chrome.png" alt="chrome"></a>
          <a href="https://microsoftedge.microsoft.com/addons/detail/behkfidincjlemlfiiabjhgdffhmhpce" class="item"><img class="item-pic" src="../assets/edge.png" alt="edge"></a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogShow: false,
    }
  },
}
</script>

<style lang="scss" scoped>
//.dialog
.dialog{
  .dialog-title{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #404040;
    text-align: center;
  }
  .dialog-sub{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    color: #B6B6B6;
    text-align: center;
  }
  .dialog-list{
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 24px;
    .item{
      width: 70px;
      height: 70px;
      border: 1px solid #F4F4F4;
      box-sizing: border-box;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 24px;
      overflow: hidden;
      cursor: pointer;
      transition: 0.2s ease;
      &:hover{
        border-color: #D6D6D6;
      }
    }
    .item-pic{
      border-radius: 16px;
      height: 66px;
      width: 66px;
    }
  }
}
</style>
