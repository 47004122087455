<template>
 <div class="header">
    <router-link to="/"><img alt="littleStar logo" src="../assets/logo.png" srcset="../assets/logo@2x.png 2x, ../assets/logo@3x.png 3x" class="logo"></router-link>
    <nav class="header-nav">
      <a target="_blank" href="https://github.com/alianrock/LittleStar/wiki" class="item">{{$t('header.doc')}}</a>
      <router-link to="/updates" :class="{'active': $route.name === 'Updates'}" class="item">{{$t('header.updates')}}</router-link>
      <router-link to="/trending"  :class="{'active': $route.name === 'Trending'}" class="item">{{$t('header.trending')}}</router-link>
      <router-link to="/pricing"  :class="{'active': $route.name === 'Price'}" class="item">{{$t('header.prices')}}</router-link>
      <el-dropdown class="item-dropdown">
        <div to="/trending"  class="item">{{$t('header.language')}}</div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="switchLang('en')"><span v-if="lang.indexOf('en') !== -1" class="el-active-dot"></span>English</el-dropdown-item>
          <el-dropdown-item @click.native="switchLang('zh')"><span v-if="lang.indexOf('zh') !== -1" class="el-active-dot"></span>Chinese(Simple)</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <a href="https://www.producthunt.com/posts/little-star-github-stars-manager?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-little-star-github-stars-manager" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=305527&theme=light" alt="Little Star - Github stars manager - Efficiently and easily manage your GitHub stars. | Product Hunt" style="width: 210px; height: 54px;margin-top: -13px;" width="250" height="54" /></a>
    </nav>
    <div class="header-right">
      <el-dropdown class="header-avatarw" v-if="user">
          <img  :src="user.avatar" class="header-avatar" alt="">
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="logOut">Sign Out</el-dropdown-item>
          </el-dropdown-menu>
      </el-dropdown>
      <a href="/home" class="btn">Web app</a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    ...mapActions({
      logOut: 'logOut',
    }),
    ...mapMutations({
      setLang: 'setLang',
    }),
    switchLang(key) {
      window.localStorage.setItem('lang', key)
      this.$i18n.locale = key
      this.setLang(key)
      this.$router.replace({
        query: {
          ...this.$route.query,
          lang: key,
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.header{
  max-width: 1572px;
  margin: 0 auto 0;
  display: flex;
  position: relative;
  .logo{
    width: 264px;
    height: 85px;
    margin: 24px 140px 0 0;
  }
  .btn{
    width: 121px;
    height: 42px;
    text-align: center;
    line-height: 42px;
    background: linear-gradient(135deg, #FCCF31 0%, #F55555 100%);
    border-radius: 30px;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    margin-top: 46px;
    position: absolute;
    right: 0;
    &:hover{
      background: linear-gradient(135deg, #fac917 0%, #ee3939 100%);
    }
  }
}
.header-avatarw{
   position: absolute;
  top: 46px;
  right: 140px;
}
.header-avatar{
  width: 40px;
  height: 40px;
  border-radius: 999px;
  border: 3px solid #eee;
}
.header-nav{
  display: flex;
  line-height: 28px;
  margin-top: 54px;
  .item-dropdown{
    height: 28px;
    margin-right: 48px;
    .item{
      margin-right: 0;
    }
  }
  .item{
    height: 28px;
    margin-right: 48px;
    font-size: 18px;
    color: #484848;
    position: relative;
    display: block;
    &::after{
      opacity: 0;
      background: #FDB059;
      content: '';
      display: block;
      width: 0;
      height: 3px;
      border-radius: 4px;
      position: absolute;
      bottom: -6px;
      left: 0;
      transition: all 0.2s ease;
    }
    .active-dot{
      height: 8px;
      width: 8px;
      background: #FDB059;
      border-radius: 999px;
      margin-right: 8px;
      display: inline-block;
    }
  }
  .active,
  .item:hover{
    &::after{
      width: 100%;
      opacity: 1;
    }
  }
}
</style>

<style>
.el-active-dot{
  height: 8px;
  width: 8px;
  background: #FDB059;
  border-radius: 999px;
  margin-right: 8px;
  display: inline-block;
}
</style>
