<template>
  <footer class="footer">
      <div class="footer-con">
        <div class="footer-left">
          <img alt="LittleStar logo" class="footer-left-logo" src="../assets/logo.png" srcset="../assets/logo@2x.png 2x, ../assets/logo@3x.png 3x">
          <div class="footer-left-des">{{$t('footer.subtitle')}}</div>
          <div class="footer-left-btns">
            <router-link to="/home" class="btn">Go app <img class="btn-icon" src="../assets/btn-icon-w.png"></router-link>
            <div class="btn" @click="showExtension">Download extension</div>
          </div>
          <div class="fotter-left-copy">© {{year}} Little Star. All rights reserved. </div>
          <div class="fotter-left-back"><a href="https://beian.miit.gov.cn/">粤ICP备20018003号</a></div>
        </div>
        <div class="footer-right">
          <div class="footer-right-r">
            <div class="footer-right-title">Product</div>
            <a href="/home" class="fotter-right-item">Web app</a>
            <div class="fotter-right-item" @click="showExtension">Brower Extension</div>
            <router-link to="/trending" class="fotter-right-item">Github Trending</router-link>
            <router-link to="/updates" class="fotter-right-item">Updates</router-link>
            <a href="https://www.producthunt.com/posts/little-star-github-stars-manager?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-little-star-github-stars-manager" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=305527&theme=light" alt="Little Star - Github stars manager - Efficiently and easily manage your GitHub stars. | Product Hunt" style="width: 250px; height: 54px;margin: 20px 0 20px;" width="250" height="54" /></a>
          </div>
          <div class="footer-right-l">
            <div class="footer-right-title">Engage</div>
            <a target="_blank" href="https://github.com/alianrock/LittleStar/wiki"  class="fotter-right-item">Docs</a>
            <a target="_blank" href="https://github.com/alianrock/LittleStar/issues" class="fotter-right-item">Issues</a>
            <router-link to="/pricing" class="fotter-right-item">Pricing</router-link>
            <router-link to="/privacy" class="fotter-right-item">Privacy Policy</router-link>
            <router-link to="/termsofservice" class="fotter-right-item">Terms of services</router-link>
            <a href="mailto:service@littlestarcloud.com" class="fotter-right-item">Email: service@littlestarcloud.com</a>
            <div class="fotter-right-item">
              <div class="fotter-right-soscial-list">
                <a class="item"  href="https://twitter.com/LittleS04647858" target="_blank">
                  <img src="../assets/Twitter.png" srcset="../assets/Twitter@2x.png 2x, ../assets/Twitter@3x.png 3x" alt="weibo" class="icon">
                </a>
                <a class="item" href="https://weibo.com/u/5471118198" target="_blank">
                  <img src="../assets/weibo.png" srcset="../assets/weibo@2x.png 2x, ../assets/weibo@3x.png 3x" alt="twitter" class="icon">
                </a>
                <a class="item" href="mailto:service@littlestarcloud.com" target="_blank">
                  <img src="../assets/email.png" srcset="../assets/email@2x.png 2x, ../assets/email@3x.png 3x" alt="facebook" class="icon">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Extension ref="extension"/>
      <CookieTip/>
    </footer>
</template>

<script>
import Extension from '@/components/Extension'
import CookieTip from '@/components/CookieTip'

export default {
  components: {
    Extension,
    CookieTip,
  },
  data () {
    return {
      year: new Date().getFullYear(),
    }
  },
  methods: {
    showExtension() {
      this.$refs.extension.dialogShow = true
    },
  },
}
</script>
<style lang="scss" scoped>

//footer
.footer{
  display: flex;
  background: url(../assets/foot.png) center bottom no-repeat;
  padding: 237px 0 147px 0;
}
.footer-con{
  width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footer-left-des{
  font-size: 18px;
  line-height: 24px;
  color: #646574;
  margin: 14px 0 30px 0;
}
.footer-left-btns{
  display: flex;
  .btn{
    /* Rectangle 138 */
    height: 54px;
    background: linear-gradient(135deg, #FCCF31 0%, #F5A255 100%);
    border-radius: 32px;
    color: #fff;
    line-height: 54px;
    font-size: 24px;
    text-align: center;
    padding: 0 18px;
    margin-right: 24px;
    cursor: pointer;
  }
  .btn:nth-child(1):hover{
    background: linear-gradient(135deg, #ffce1b 0%, #f39237 100%);
  }
  .btn:nth-child(2) {
    background: none;
    color: #F5A255;
    border: 1px solid #FBC836;
    &:hover{
      border-color: #F5A255;
      color: #F5A255;
    }
  }
}
.fotter-left-copy{
  /* © 2020 Little Star 版权所有 */
  font-size: 14px;
  line-height: 20px;
  color: #838280;
  margin-top: 32px;
}
.fotter-left-back{
  margin-top: 8px;
}
.fotter-left-back a{
  color: #838280;
}
.footer-right{
  display: flex;
}
.footer-right-title{
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #646574;
  margin-bottom: 16px;
}
.fotter-right-item{
  font-size: 14px;
  line-height: 20px;
  color: #838280;
  margin-bottom: 16px;
  display: block;
  cursor: pointer;
  &:hover{
    color: #46474b;
  }
}
.footer-right-r{
  margin-right: 90px;

}
.fotter-right-soscial-list{
  display: flex;
  .item{
    margin-right: 16px;
    width: 26px;
    height: 26px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px 2px rgba(163, 163, 163, 0.22);
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon{
      width: 20px;
      height: 20px;
    }
  }
}
</style>
