<template>
  <!-- price -->
    <div class="price" >
      <h2 class="index-title">
        Pricing
      </h2>
      <div class="price-con" v-loading="loading">
        <div class="price-box price-left">
          <div class="price-type">BASIC</div>
          <div class="price-num price-num_1">Free</div>
          <ul class="price-list">
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🏷</span>{{$t('prices.free.1')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📔</span>{{$t('prices.free.21')}}<mark class="mark">{{$t('prices.free.22')}}</mark>{{$t('prices.free.23')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🌐</span>{{$t('prices.free.3')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📈</span>{{$t('prices.free.4')}}
            </li>
            <li class="price-item price-item_line">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🌝</span>{{$t('prices.free.5')}}
            </li>
            <li class="price-item price-item_line">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📦</span>{{$t('prices.free.6')}}
            </li>
            <li class="price-item price-item_line">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🚧</span>{{$t('prices.free.7')}}
              <ul class="price-item-sub">
                <li class="pice-item-sub-item">
                  - {{$t('prices.free.8')}}
                </li>
                <li class="pice-item-sub-item">
                  - {{$t('prices.free.9')}}
                </li>
                <li class="pice-item-sub-item">
                  - {{$t('prices.free.10')}}
                </li>
              </ul>
            </li>
          </ul>
          <router-link to="/home" class="price-btn">{{$t('prices.try')}}</router-link>
        </div>
        <div class="price-box price-right">
          <div class="price-tag">Black Friday</div>
          <!-- <div class="price-tag">Early bird price</div> -->
          <div class="price-type">PREMIUM</div>
          <div class="price-num price-num_2" v-if="priceType === 'month'">
            $2
            <div class="price-num-through">$3.9</div>
            <div class="price-num-unit">/month</div>
          </div>
          <div class="price-num price-num_2" v-else>
            $19.2
            <div class="price-num-through">$37</div>
            <div class="price-num-unit">/year</div>
          </div>
          <div class="price-tab">
            <div class="price-tab-slide" :class="{'price-tab-slide_year': priceType === 'year'}"></div>
            <div class="price-tab-itemw">
              <div class="price-tab-item" @click="priceType = 'month'" >Monthly</div>
              <div class="price-tab-item" @click="priceType = 'year'" >
                Yearly
                <div class="off">{{$t('prices.off')}},{{ priceType === 'year' ? '$11.52' : '$1.2'}} only!</div>
              </div>
            </div>
          </div>
          <div class="price-renew">({{$t('prices.renewal')}})</div>
          <ul class="price-list">
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🏷</span>{{$t('prices.premium.1')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📔</span>{{$t('prices.premium.21')}}<mark class="mark">{{$t('prices.premium.22')}}</mark>{{$t('prices.premium.23')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🌐</span>{{$t('prices.premium.3')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📈</span>{{$t('prices.premium.4')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🌝</span>{{$t('prices.premium.5')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📦</span>{{$t('prices.premium.6')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🚧</span>{{$t('prices.premium.7')}}
              <ul class="price-item-sub">
                <li class="pice-item-sub-item">
                  - {{$t('prices.premium.8')}}
                </li>
                <li class="pice-item-sub-item">
                  - {{$t('prices.premium.9')}}
                </li>
                <li class="pice-item-sub-item">
                  - {{$t('prices.premium.10')}}
                </li>
              </ul>
            </li>
          </ul>
          <!-- <div  class="price-btn price-btn_sub price-btn_dis">Coming soon!</div> -->
          <div v-loading="priceLoading" class="price-btn price-btn_sub" @click="subscribe">{{$t('prices.sub')}}
          <span class="sub_tip">({{$t('prices.sub_tip')}})</span>
          </div>
          <router-link class="price-servie" to="/termsofservice" >Terms of services</router-link>
        </div>
        <div class="price-box  price-black">
          <div class="price-tag">Black Friday</div>
          <div class="price-type">One Year</div>
          <div class="price-num price-num_2">
            $11.52
            <div class="price-num-through">$19.2</div>
            <div class="price-num-unit">/year</div>
          </div>
          <div class="price-discount">
              <div class="price-discount-item-num">60%</div>
              <div class="price-discount-item-text">OFF</div>
          </div>
          <ul class="price-list">
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🏷</span>{{$t('prices.premium.1')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📔</span>{{$t('prices.premium.21')}}<mark class="mark">{{$t('prices.premium.22')}}</mark>{{$t('prices.premium.23')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🌐</span>{{$t('prices.premium.3')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📈</span>{{$t('prices.premium.4')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🌝</span>{{$t('prices.premium.5')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">📦</span>{{$t('prices.premium.6')}}
            </li>
            <li class="price-item">
              <!-- <img src="../assets/check.png" alt="check" class="price-item-check"> -->
              <span class="emoji">🚧</span>{{$t('prices.premium.7')}}
              <ul class="price-item-sub">
                <li class="pice-item-sub-item">
                  - {{$t('prices.premium.8')}}
                </li>
                <li class="pice-item-sub-item">
                  - {{$t('prices.premium.9')}}
                </li>
                <li class="pice-item-sub-item">
                  - {{$t('prices.premium.10')}}
                </li>
              </ul>
            </li>
          </ul>
          <!-- <div  class="price-btn price-btn_sub price-btn_dis">Coming soon!</div> -->
          <div v-loading="priceLoading" class="price-btn price-btn_sub" @click="payOneTime">{{$t('prices.order_now')}}</div>
          <!-- <router-link class="price-servie" to="/termsofservice" >Terms of services</router-link> -->
        </div>
      </div>
    </div>
    <!-- price -->
</template>
<script>
import { mapState } from 'vuex'
import { Github } from '@/config/'

export default {
  data() {
    return {
      priceType: 'year',
      productMap: {},
      loading: true,
      priceLoading: false,
      stripeClientKey: '',
      stripe: null,
      waitStripeTimer: null,
    }
  },
  mounted () {
    this.loading = true
    Promise.all([this.getStripeKey(), this.getPrices()]).then(() => {
      this.loading = false
      if (this.$route.query.sid) {
        this.stripeRedirect(this.$route.query.sid)
      }
    })
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    getStripeKey() {
      return this.$http.get('/pay/stripe/key').then((res) => {
        this.stripeClientKey = res.key
        // this.stripe = new Stripe(this.stripeClientKey)
      })
    },
    getPrices() {
      return this.$http.get('/pay/stripe/price').then((res) => {
        this.productMap = res.data
      })
    },
    subscribe() {
      if (this.user) {
        this.createNewOrder()
      } else {
        this.login()
      }
    },
    payOneTime() {
      this.priceType = 'one_time'
      this.createNewOrder()

      // if (this.user) {
      //   this.createNewOrder()
      // } else {
      //   this.login()
      // }
    },
    createNewOrder() {
      this.priceLoading = true
      const id = this.productMap[this.priceType].id
      // this.stipeOrderLoading = true
      this.$http.post('/pay/stripe/order', {
        priceId: id,
        type: this.priceType,
      }).then((res) => {
        // this.stripeclientSecret = res.client_secret
        // this.payModel.showStripe = true
        // const s = new Stripe()
        this.stripeRedirect(res.id)
      }).finally(() => {
        this.priceLoading = false
      })
    },
    stripeRedirect(id) {
      if (typeof Stripe !== 'undefined') {
        clearInterval(this.waitStripeTimer)
        const stripe = new Stripe(this.stripeClientKey)
        stripe.redirectToCheckout({
          sessionId: id,
        }).then((res) => {
          console.log(res)
        })
      } else {
        this.waitStripeTimer = setTimeout(() => {
          this.stripeRedirect(id)
        }, 1000)
      }
    },
    login() {
      const id = this.productMap[this.priceType].id
      window.location.href = Github.authUrl + '?type=price&priceId=' + id
      // window.open(Github.authUrl, 'newwindow', 'height=500,width=600,top=100,left=400,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no')
    },
  },
}
</script>

<style lang="scss" scoped>

.index-title{
  /* More Featrues */
  font-family: 'Fredoka One';
  font-size: 52px;
  color: #272727;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding-bottom: 26px;
  // margin-top: 100px;
  margin-bottom: 100px;
  &::after{
    content:'';
    display: block;
    /* Rectangle 133 */
    position: absolute;
    width: 317px;
    height: 10px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background: #F0F0F0;
    border-radius: 12px;
  }

}
// price
.price{
  padding-top: 56px;
}
.price-con{
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.price-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Rectangle 39 */
  background: #FFFFFF;
  box-shadow: 0px 13px 48px rgba(226, 226, 226, 0.6);
  border-radius: 32px;
  margin: 0 26px;
  padding-bottom: 34px;
  position: relative;
}
.price-list{
  flex: 1;
}
.price-left{
  width: 400px;
}
.price-right{
  width: 480px;
  background: url(../assets/price@2x.png) left bottom no-repeat;
  background-size: 163px 207px;
  padding-left: 40px;
  box-sizing: border-box;
  overflow: hidden;
}
.price-type{
  /* MEDIUM */
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height, or 147% */
  letter-spacing: 0.2em;
  /* Heading text */
  color: #081F32;
  text-align: center;
  padding: 38px 0 12px 0;
}
.price-renew{
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #A0A0A0;
  margin-bottom: 18px;
  margin-top: 10px;
}
.price-num{
  /* Free */
  font-weight: 500;
  font-size: 64px;
  line-height: 72px;
  /* identical to box height, or 35% */

  /* Heading text */
  color: #081F32;
  text-align: center;
  display: flex;
  align-items: flex-end;
}
.price-num-through{
  color: #949494;
  font-size: 24px;
  font-weight: normal;
  position: relative;
  top: 14px;
  text-decoration: line-through;

}
.price-num-unit{
  /* /mo */
  font-family: DM Serif Display;
  font-size: 22px;
  line-height: 26px;
  color: #6E798C;
  margin-bottom: 8px;
}
.price-num_2{
  margin-bottom: 24px;
}
.price-num_1{
  margin-bottom: 100px;
}
.price-tag{
  position: absolute;
  background: linear-gradient(135deg, #414141 0%, #000000 100%);
  // background: linear-gradient(135deg, #FCCF31 0%, #F5A255 100%);
  color: #fff;
  height: 30px;
  line-height: 30px;
  padding: 0 40px;
  font-size: 16px;
  text-shadow: 1px 1px rgba(0,0,0,0.2);
  top: 34px;
  left: -44px;
  transform: rotate(-45deg);
  &::after,
  &::before{
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    top: 2px;
    left: 0;
    border-top: 1px dashed #fff;
  }
  &::before{
    top: 27px;
  }
}
.price-item{
  font-size: 18px;
  line-height: 25px;
  color: #5B6474;
  margin-bottom: 16px;
  .mark{
    display: inline-block;
    margin: 0 4px;
    background: none;
    color: #F5BA26;
  }
  .emoji{
    display: inline-block;
    margin-right: 8px;
  }
}
.price-item-sub{
  font-size: 16px;
  padding-left: 30px;
  color: #afafaf;
  padding-top: 10px;
}
.price-item_line{
   color: #d4d4d4;
   text-decoration: line-through;
  .price-item-sub{
   color: #d4d4d4;
  }
  .emoji{
   text-decoration: line-through;
  }
}
.price-item-check{
  margin-right: 8px;
}
.price-btn{
  margin-top: 48px;
  overflow: hidden;
  /* Rectangle 138 */
  width: 205px;
  height: 54px;
  border: 1px solid #FBC836;
  box-sizing: border-box;
  border-radius: 32px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  color: #F8B348;
  cursor: pointer;
  &:hover{
    border-color: #F5A255;
    color: #F5A255;
  }
  .sub_tip{
    display: block;
    font-size: 12px;
  }
}
.price-btn_sub{
  width: 248px;
  height: 54px;
  border: none;
  // background: linear-gradient(135deg, #FCCF31 0%, #F5A255 100%);
  background: linear-gradient(135deg, #ffac00 0%, #ff4f0c 100%);
  border-radius: 32px;
  color: #fff;
  &:hover{
    color: #fff;
    background: linear-gradient(135deg, #ffce1b 0%, #f39237 100%);
  }
}
.price-btn_dis{
  opacity: 0.7;
  cursor: unset;
}
.price-tab{
  display: flex;
  /* Vector */
  background: rgba(224, 224, 224, 0.5);
  border-radius: 8px;
  box-shadow: inset 0px 1.545045px 1.09009px rgba(97, 97, 97, 0.2);
  padding: 4px;
  cursor: pointer;
  width: 130px;
  height: 26px;
  position: relative;
}
.price-tab-itemw{
  width: 100%;
  display: flex;
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
}
.price-tab-item{
  height: 26px;
  line-height: 26px;
  padding: 0 6px;
  width: 50%;
  color: #626262;
  font-size: 14px;
  text-align: center;
  position: relative;
  z-index: 2;
  top: 4px;
  .off{
    /* Rectangle 136 */
    position: absolute;
    // width: 56px;
    padding: 4px;
    height: 14px;
    right: -30px;
    top: -32px;
    white-space: nowrap;
    background: #FF7676;
    border-radius: 6px;
    position: absolute;
    color: #fff;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #FFFFFF;
  }
}
.price-tab-slide{
  width: 50%;
  height: 26px;
  position: relative;
  z-index: 1;
  background: #fff;
  border-radius: 6px;
  margin: 0;
  box-shadow:  0px 1px 1px rgba(97, 97, 97, 0.2);
  transition: 0.2s ease;
}
.price-tab-slide_year{
  transform: translate3d(100%, 0, 0);
}
.price-servie {
  font-size: 14px;
  line-height: 20px;
  color: #838280;
  margin-bottom: 16px;
  display: block;
  cursor: pointer;
  margin-top: 10px;
  text-decoration: underline;
  &:hover{
    color: #46474b;
  }
}
.price-black{
  width: 380px;
  display: none;
  // border: 4px solid;
  // border-image: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet) 1;
  overflow: hidden;
  border-radius: 20px;
  border-radius: 15px;
  box-shadow: 0 0 0 10px inset linear-gradient(to right, #ff7e5f, #feb47b);
  padding: 10px;
  // 写一个渐变背景，使用一些舒服的颜色
  background: linear-gradient(135deg, #414141 0%, #000000 100%);
  .price-tag{
    background: linear-gradient(135deg, #414141 0%, #000000 100%);
  }
  .price-type,
  .price-item{
    color: #fff;
  }
  .price-num{
    color: #fff;
  }
  .price-btn{
    // background: linear-gradient(135deg, #7e7512 0%, #c9c9c9 100%);
    background: linear-gradient(135deg, #ffac00 0%, #ff4f0c 100%);
    margin-bottom: 29px;
  }
}
.price-discount{
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  background: #ff4444;
  border-radius: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 40px;
}
</style>
