module.exports = {
  header: {
    doc: 'Docs',
    updates: 'Updates',
    trending: 'GitHub Trending',
    prices: 'Pricing',
    language: 'Language',
  },
  banner: {
    extensionBtn: 'Browser Extension',
    des: 'Manage Github stars efficiently and easily with browser extension',
  },
  des: {
    title1_1: 'Add categories with',
    title1_2: ' browser extension ',
    title1_3: '',
    des1_1: 'Browser extensions make it easier and more efficient to add categories. Click the Star button on the GitHub page, then edit categories directly,',
    des1_2: 'no tedious process.',
    title2_1: 'Manage',
    title2_2: ' and ',
    title2_3: 'search',
    title2_4: ' stars',
    des2_1: 'Manage all your projects in the App,',
    des2_2: ' quickly find and locate',
    des2_3: ', no longer for the trouble of finding projects.',
    title3_1: 'Explore ',
    title3_2: 'good projects quickly',
    des3_1: 'The Explore module ',
    des3_2: 'makes it easy for you to find good projects. Click the Star button and you can directly categorize and bookmark them in the App. More exploration features will be added later.',
    title4_1: 'Share ',
    title4_2: 'your categories',
    des4_1: 'If you settled a great category of stars, you can',
    des4_2: ' share ',
    des4_3: 'the entire category with your friends.',
  },
  features: {
    title1: 'Notes',
    des1: 'Optimized reading interface, record your thoughts on each project. ',
    title2: 'File tree',
    des2: 'Project files and code can be viewed directly in Little Star without having to jump back to GitHub.',
    title3: 'Personalized data presentation',
    des3: 'GitHub submits data charts, project number charts, project stars trend charts, etc.',
  },
  prices: {
    free: {
      1: 'GitHub stars manage',
      21: 'Create',
      22: '100',
      23: 'categories',
      3: 'Share your stars category',
      4: 'Github Trending',
      5: 'Auto-Backup',
      6: 'Use unlimited icons',
      7: 'More Features...',
      8: 'Project notes📒',
      9: 'File tree🌲',
      10: 'Personalized data presentation',
    },
    premium: {
      1: 'GitHub stars manage',
      21: 'Create',
      22: 'unlimited',
      23: 'categories',
      3: 'Share your stars category',
      4: 'Github Trending',
      5: 'Auto-Backup',
      6: 'Use unlimited icons',
      7: 'More Features...',
      8: 'Project notes📒',
      9: 'File tree🌲',
      10: 'Personalized data presentation',
    },
    order_now: 'Order Now!',
    renewal: 'Auto-renewal',
    off: '40% off use coupon code!, ',
    try: 'Try it now!',
    sub_tip: '',
    sub: 'Subscribe now!',
  },
  footer: {
    subtitle: 'Manage Github stars efficiently and easily.',
  },
  trend: {
    selectDatePlaceHolder: 'Date range',
    selectLangPlaceHolder: 'Language',
    allLang: 'All language',
    daily: 'Today',
    weekly: 'This week',
    monthly: 'This month',
  },
  emailPanel: {
    editBtn: 'Edit email address',
    placeholder: 'Email address',
    verifyBtn: 'SEND VERIFICATION EMAIL',
    cancelBtn: 'Cancel',
    sendTip: 'A verification email was sent to',
    reSendTip: 'No received？Send agian.',
    varifyUpdate: 'EMAIL VERIFIED',
    updateSucc: 'Update Success',
    emailVarifyErr: 'Invalid email format',
    emailReqSuccTip: 'A verification email was sent to you email address',
    verifyTip: 'Please verify your email address',
    verifyTip_2: 'We just need you to verify your email address to complete setting up your account.',
  },
}
