<template>
<div class="c_toast_w" @click.stop="show = false" :class="{ 'c_toast_w_dark': store && store.state.darkMode,
  'c_toast_w_show': show && isConfirm,
  'c_toast_w_confirm': show && isConfirm,
  'c_toast_w_show_tip': show && !isConfirm}">
  <div class="c_toast"  @click.stop>
    <div class="tip">{{msg}}</div>
    <div class="btnw">
      <div class="btn btn_cancel" @click.stop="cancel" >{{i18n.t('c_toast.btnCancel')}}</div>
      <div class="btn" @click.stop="confirm" >{{i18n.t('c_toast.btn')}}</div>
    </div>
  </div>
  <div class="c_toast_tip"  >
    {{msg}}
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      msg: '',
      isConfirm: false,
      store: null,
    }
  },
  watch: {
    show (val) {
      if (val) {
        document.addEventListener('keydown', this.keydownHandler, false)
      } else {
        document.removeEventListener('keydown', this.keydownHandler)
      }
    },
  },
  methods: {
    hide () {
      console.log('hide')
      this.show = false
    },
    keydownHandler (e) {
      if (e.keyCode === 27) {
        this.show = false
      }
    },
    confirm: () => {
    },
    cancel: () => {},
  },
}
</script>

<style lang="scss" scoped>
.c_toast_w{
  // height: 100%;
  // width: 100%;
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // top: 0;
  // opacity: 0;
  // visibility: hidden;
  // transition: 0.2s ease;
  // z-index: 301;
}
.c_toast_w_confirm{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  visibility: visible;
  transition: 0.2s ease;
  z-index: 301;
}
.c_toast_w_show{
  .c_toast{
    opacity: 1;
    visibility: visible;
    transform: translate3d(-50%, -50%, 0);
  }
}
.c_toast{
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease;
  background-image: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%, #FFFFFF 100%);
  border: 1px solid #A8A8A8;
  border-radius: 8px;
  min-height: 158px;
  width: 296px;
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 302;
  transform: translate3d(-50%, -40%, 0);
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.3);
  .tip{
    line-height: 22px;
    font-size: 16px;
    color: #464646;
    text-align: center;
    word-break: break-all;
    padding: 42px 30px  29px 30px;
  }
  .btnw{
    display: none;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
  }
  .btn{
    margin-left: -84px;
    border-radius: 999px;
    color: #FFF;
    background: #2E7FFF;
    text-align: center;
    line-height: 32px;
    height: 32px;
    width: 104px;
    margin: 0 8px;
    // opacity: 0;
    // visibility: hidden;
    box-sizing: content-box;
    z-index: 1000;
    box-shadow: 0 0 3px 0 rgba(135,135,135,0.50);
    cursor: pointer;
    &:hover{
      background: #266FE1;
    }
  }
  .btn_cancel{
    color: #7B7B7B;
    background: #FFFFFF;
    border: 1px solid rgba(196,196,196,0.00);
    border-radius: 999px;
     &:hover{
      background-image: linear-gradient(180deg, #FFFFFF 0%, #fcfcfc 98%);
    }
  }
}

.c_toast_tip{
  position: fixed;
  left: 50%;
  top: auto;
  transform: translate3d(-50%, -40%, 0);
  min-height: 16px;
  color: #fff;
  border: none;
  height: auto;
  font-size: 16px;
  padding: 16px 16px;
  text-align: center;
  border-radius: 999px;
  width: 200px;
  background: rgba(0,0,0,0.90);
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.50);
  bottom: -32px;
  visibility: hidden;
  transition: 0.3s ease;
  opacity: 0;
  z-index: 302;
}

.c_toast_w_show_tip{
  opacity: 1;
  visibility: visible;
  background: none;
  .c_toast_tip{
    opacity: 1;
    visibility: visible;
    transform: translate3d(-50%, -150px, 0);
  }
}

// dark
.c_toast_w_dark{
  .c_toast{
    background-image: linear-gradient(180deg, #353535 0%, #232323 100%, #2b2b2b 100%);
    border: 1px solid #444444;
    .tip{
      color: #fff;
    }
    .btn{
      box-shadow: none;
    }
  }
}

</style>
