<template>
  <div class="home" :class="{home_en: isEn}">
    <Header/>
    <!-- banner -->
    <div class="banner">
      <div class="banner-title">
        <h1 class="name">Little Star</h1>
        <p class="des">{{$t('banner.des')}}</p>
        <div class="btns">
          <div class="btn" @click="showExtensionDialog">{{$t('banner.extensionBtn')}}</div>
          <a href="/home" class="btn">Web app <img src="../assets/btn-icon.png"></a>
        </div>
      </div>
      <div class="banner-pic-w">
        <img @click="showVideo = true" :src="require('@/assets/play.png')" srcset="../assets/play@2x.png 2x, ../assets/play@3x.png 3x" class="play-btn" >
        <img class="banner-pic" src="../assets/banner.png" srcset="../assets/banner@2x.png 2x, ../assets/banner@3x.png 3x"/>
      </div>
    </div>
    <!-- banner -->

    <!-- pic1 -->
    <div class="pic pic1" :class="{'pic_active': pic1Active}" ref="pic1">
      <img src="../assets/pic1.png" srcset="../assets/pic1@2x.png 2x, ../assets/pic1@3x.png 3x" alt="结合插件添加分类" class="pic-img">
      <div class="pic-text">
        <h2 class="pic-title">{{$t('des.title1_1')}}<span class="light">{{$t('des.title1_2')}}</span>{{$t('des.title1_3')}}</h2>
        <p class="pic-des">{{$t('des.des1_1')}} <mark class="mark">{{$t('des.des1_2')}}</mark></p>
      </div>
    </div>
    <!-- pic1 -->
    <!-- pic1 -->
    <div class="pic pic2"  :class="{'pic_active': pic2Active}"  ref="pic2">
      <div class="pic-text">
        <h2 class="pic-title"><span class="light">{{$t('des.title2_1')}}</span>{{$t('des.title2_2')}}<span class="light">{{$t('des.title2_3')}}</span>{{$t('des.title2_4')}}</h2>
        <p class="pic-des">{{$t('des.des2_1')}}<mark class="mark">{{$t('des.des2_2')}}</mark> {{$t('des.des2_3')}}</p>
      </div>
      <img src="../assets/pic2.png" srcset="../assets/pic2@2x.png 2x, ../assets/pic2@3x.png 3x" alt="结合插件添加分类" class="pic-img">
    </div>
    <!-- pic1 -->
    <!-- pic1 -->
    <div class="pic pic3"  :class="{'pic_active': pic3Active}"  ref="pic3">
      <img src="../assets/pic3.png" srcset="../assets/pic3@2x.png 2x, ../assets/pic3@3x.png 3x" alt="结合插件添加分类" class="pic-img">
      <div class="pic-text">
        <h2 class="pic-title"><span class="light">{{$t('des.title3_1')}}</span>{{$t('des.title3_2')}}</h2>
        <p class="pic-des"><mark class="mark">{{$t('des.des3_1')}}</mark>{{$t('des.des3_2')}}</p>
      </div>
    </div>
    <!-- pic1 -->
    <!-- pic1 -->
    <div class="pic pic4"  :class="{'pic_active': pic4Active}"  ref="pic4">
      <div class="pic-text">
         <h2 class="pic-title"><span class="light">{{$t('des.title4_1')}}</span>{{$t('des.title4_2')}}</h2>
        <p class="pic-des">{{$t('des.des4_1')}}<mark class="mark">{{$t('des.des4_2')}}</mark>{{$t('des.des4_3')}}</p>
      </div>
      <img src="../assets/pic4.png" srcset="../assets/pic4@2x.png 2x, ../assets/pic4@3x.png 3x" alt="结合插件添加分类" class="pic-img">
    </div>
    <!-- pic1 -->

    <!-- feature -->
    <div class="feature" ref="feature" :class="{'feature_active': featureActive}">
      <h2 class="index-title">
        More Features
      </h2>
      <div class="list">
        <div class="item">
          <h3 class="item-title">
            <img src="../assets/feature-icon1.png" srcset="../assets/feature-icon1@2x.png 2x, ../assets/feature-icon1@3x.png 3x" alt="" class="item-icon">
            {{$t('features.title1')}}
          </h3>
          <p class="item-text">{{$t('features.des1')}}</p>
        </div>
        <div class="item">
          <h3 class="item-title">
            <img src="../assets/feature-icon2.png" srcset="../assets/feature-icon2@2x.png 2x, ../assets/feature-icon2@3x.png 3x" alt="" class="item-icon">
            {{$t('features.title2')}}
          </h3>
          <p class="item-text">{{$t('features.des2')}}</p>
        </div>
        <div class="item">
          <h3 class="item-title">
            <img src="../assets/feature-icon3.png" srcset="../assets/feature-icon3@2x.png 2x, ../assets/feature-icon3@3x.png 3x" alt="" class="item-icon">
            {{$t('features.title3')}}
          </h3>
          <p class="item-text">{{$t('features.des3')}}</p>
        </div>
      </div>
    </div>
    <!-- feature -->

    <!-- price -->
    <Price/>
    <!-- price -->

    <!-- footer -->
    <Footer/>
    <!-- footer -->
    <Extension ref="extension"/>

    <div class="video_w" @click="showVideo = false" id="video_w" :class="{'video_w_show': showVideo}">
      <div  class="video" controls src="https://youtu.be/tilbhPob3KI" id="video">
        Your browser does not support the <code>video</code> element.
        <iframe class="video" @click.stop width="560" height="315" src="https://www.youtube.com/embed/tilbhPob3KI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <div class="close_w" @click="showVideo = false" id="video-close">
        <img src="../assets/close.png" class="close" srcset="../assets/close@2x.png 2x, ../assets/close@3x.png 3x" alt="">
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import Price from '@/components/Price'
import Extension from '@/components/Extension'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    Header,
    Extension,
    Footer,
    Price,
  },
  data () {
    return {
      year: new Date().getFullYear(),
      pic1Top: 0,
      pic2Top: 0,
      pic3Top: 0,
      pic4Top: 0,
      featureTop: 0,
      pic1Active: false,
      pic2Active: false,
      pic3Active: false,
      pic4Active: false,
      featureActive: false,
      isEn: true,
      dialogShow: false,
      showVideo: false,
    }
  },
  mounted () {
    this.isEn = this.$i18n.locale.indexOf('en') !== -1
    this.positionInit()
    this.onScroll()
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    showExtensionDialog() {
      this.$refs.extension.dialogShow = true
    },
    positionInit () {
      const scrollTop = this.getSrollTop()

      this.pic1Top = scrollTop + this.$refs.pic1.getBoundingClientRect().top
      this.pic2Top = scrollTop + this.$refs.pic2.getBoundingClientRect().top
      this.pic3Top = scrollTop + this.$refs.pic3.getBoundingClientRect().top
      this.pic4Top = scrollTop + this.$refs.pic4.getBoundingClientRect().top
      this.featureTop = scrollTop + this.$refs.feature.getBoundingClientRect().top
    },
    getSrollTop () {
      let scrollTop = 0
      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop
      } else if (document.body) {
        scrollTop = document.body.scrollTop
      }
      return scrollTop
    },
    onScroll () {
      document.addEventListener('scroll', (e) => {
        const scrollTop = this.getSrollTop()
        const clientHeight = (document.documentElement && document.documentElement.clientHeight) || (document.body && document.body.clientHeight)
        const position = scrollTop + (clientHeight / 2)
        if (position > this.pic1Top) {
          this.pic1Active = true
        } else {
          this.pic1Active = false
        }
        if (position > this.pic2Top) {
          this.pic2Active = true
        } else {
          this.pic2Active = false
        }
        if (position > this.pic3Top) {
          this.pic3Active = true
        } else {
          this.pic3Active = false
        }
        if (position > this.pic4Top) {
          this.pic4Active = true
        } else {
          this.pic4Active = false
        }
        if (position - 30 > this.featureTop) {
          this.featureActive = true
        } else {
          this.featureActive = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.home{
  overflow: hidden;
}

// banner
.banner{
  max-width: 1572px;
  margin: 0 auto 0;
  display: flex;
  .banner-title{
    padding-top: 352px;
    margin-left: 38px;
    .name{
      font-family: 'FredokaOne';
      font-size: 64px;
      line-height: 64px;
      color: #3C3C3C;
      margin-bottom: 16px;
      position: relative;
      &::after,
      &::before{
        position: absolute;
        width: 16px;
        height: 16px;
        left: 8px;
        top: -53px;
        content: '';
        display: block;
        background: linear-gradient(135deg, #FE637F 0%, #FF6637 100%);
        opacity: 0.6;
        border-radius: 99px;
      }
      &::after{
        height: 8px;
        width: 8px;
        top: -31px;
        left: -12px;
        /* Ellipse 12 */
        background: linear-gradient(135deg, rgba(254, 193, 99, 0.8) 0%, rgba(222, 67, 19, 0.8) 100%);
        opacity: 0.6;
      }
    }
    .des{
      font-size: 32px;
      color: #646574;
      line-height: 46px;
      margin-bottom: 24px;
    }
    .btns{
      display: flex;
      .btn{
        cursor: pointer;
      }
    }
    .btns .btn:nth-child(1){
      width: 256px;
      height: 58px;
      line-height: 58px;
      text-align: center;
      color: #fff;
      background: linear-gradient(135deg, #55B8FF 0%, #008BEF 100%);
      border-radius: 100px;
      font-size: 24px;
      text-align: center;
      &:hover{
        background: linear-gradient(135deg, #71c4ff 0%, #1da1ff 100%);
      }
    }
    .btns .btn:nth-child(2){
      width: 160px;
      height: 56px;
      line-height: 56px;
      border: 1px solid #48B2FF;
      box-sizing: border-box;
      border-radius: 100px;
      margin-left: 16px;
      color: #48B2FF;
      font-size: 24px;
      text-align: center;
      &:hover{
        color: rgb(70, 153, 255);
        border-color:  #48B2FF;
      }
    }
  }
  .banner-pic-w{
    position: relative;
    .play-btn{
      position: absolute;
      width: 74px;
      height: 74px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, 200%);
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      cursor: pointer;
      transition: 0.4s cubic-bezier(0.38, 0.21, 0.09, 1.32);
    }
  }
  .banner-pic-w:hover{
    .play-btn{
      transform: translate3d(-50%, -50%, 0);
      visibility: visible;
      opacity: 1;
    }
    .banner-pic{
      filter: blur(1px);
    }
  }
  .banner-pic{
    margin-top: 27px;
    margin-right: 18px;
    width: 966px;
    height: 869px;
  }
}
.pic{
  width: 1572px;
  margin: 0 auto 0;
  display: flex;
  align-items: center;
}
.pic-img,
.pic-text{
  transition: 0.6s ease;
}
.pic-title{
  font-weight: 600;
  font-size: 52px;
  line-height: 73px;
  color: #3F3F48;
  margin-bottom: 27px;
}
.pic:nth-child(2n + 1) {
  .pic-img{
    opacity: 0;
    transform: translate3d(-300px, 0, 0);
  }
  .pic-text{
    opacity: 0;
    transform: translate3d(300px, 0, 0);
  }
}
.pic:nth-child(2n) {
  .pic-img{
    opacity: 0;
    transform: translate3d(300px, 0, 0);
  }
  .pic-text{
    opacity: 0;
    transform: translate3d(-300px, 0, 0);
  }
}

.pic_active{
  .pic-img{
    opacity: 1!important;
    transform: translate3d(0, 0, 0)!important;
  }
  .pic-text{
    opacity: 1!important;
    transform: translate3d(0, 0, 0)!important;
  }
}
.pic-des{
  font-size: 18px;
  line-height: 32px;
  color: #3F3F48;
  .mark{
    color: #3F3F48;
    position: relative;
    background: none;
    color: none;
    &::after{
      content:'';
      display: block;
      width: 100%;
      height: 8px;
      left: 0;
      position: absolute;
      bottom: -8px;
      border-radius: 4px;
    }
  }
}
.pic1{
  .pic-img{
    height: 851px;
    width: 864px;
    margin-right: 42px;
    margin-left: -54px;
  }
  .light{
    color: #FE7700;
  }
  .pic-des{
    width: 555px;
  }
  .mark{
    &:after{ background:  #FFDDBF; }
  }
}

.pic2{
  height: 867px;
  position: relative;
  margin-top: -62px;
  .pic-img{
    height: 867px;
    width: 1250px;
    position: absolute;
    right: -48px;
    top: 0;
  }
  .light{
    color: #2F80ED;
  }
  .light:nth-child(2){
    color: #2D9CDB;
  }
  .pic-text{
    text-align: right;
    margin-left: 26px;
  }
  .pic-des{
    width: 444px;
  }
  .mark{
    &:after{ background:  #B0E3FF; }
  }
}
.pic3{
  padding-top: 56px;
  .light{
    color: #496FD9;
  }
  .pic-text{
    padding-left: 124px;
  }
  .pic-des{
    width: 555px;
  }
  .pic-img{
    margin-left:-74px;
  }
  .mark{
    &:after{ background:  #F3E5FF; }
  }
}
.pic4{
  .pic-img{
    width: 937px;
    height: 800px;
    margin-right: 52px;
  }
  justify-content: space-between;
  .light{
    color: #FF5252;
  }
  .pic-des,
  .pic-title{
    text-align: right;
  }
  .pic-text{
    margin-left: 11px;
  }
  .mark{
    &:after{ background:  #FFDDBF; }
  }
}

.index-title{
  /* More Featrues */
  font-family: 'Fredoka One';
  font-size: 52px;
  color: #272727;
  text-align: center;
  position: relative;
  margin: 0 auto;
  padding-bottom: 26px;
  margin-top: 200px;
  margin-bottom: 100px;
  &::after{
    content:'';
    display: block;
    /* Rectangle 133 */
    position: absolute;
    width: 317px;
    height: 10px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    background: #F0F0F0;
    border-radius: 12px;
  }

}
//feature
.feature{
  margin-top: 96px;
  margin-bottom: 200px;
  .list{
    display: flex;
    justify-content: center;
    margin-top: 95px;
  }
  .item{
    /* Rectangle 38 */
    margin: 0 60px;
    width: 334px;
    height: 314px;
    background-color: #fff;
    position: relative;
    border-radius: 32px;
    box-shadow: 0px 13px 48px rgba(226, 226, 226, 0.6);
    transition: 0.5s ease;
    opacity: 0;
    transform: translate3d(0, 100px ,0);
    // &:hover{
    //   transition-delay: 0!important;
    //   transform: translate3d(0, -50px ,0);
    // }
  }
  .item:nth-child(1){
    background: url(../assets/feature@2x.png) center bottom no-repeat;
    background-color: #fff;
    background-size: cover;
    transition-delay: 0;
    &::after{
      content:'';
      position: absolute;
      width: 53.7px;
      height: 53.85px;
      left: -41px;
      z-index: -1;
      border-radius: 999px;
      background: linear-gradient(135deg, rgba(252, 207, 49, 0.2) 0%, rgba(245, 85, 85, 0.2) 100%);
      opacity: 0.5;
    }
  }
  .item:nth-child(2){
    background: url(../assets/feature1@2x.png) center bottom no-repeat;
    background-size: cover;
    background-color: #fff;
    transition-delay: 0.3s;
    &::after{
      content:'';
      position: absolute;
      display: block;
      z-index: -1;
      width: 72px;
      height: 72px;
      left: -15px;
      top: 8px;
      border-radius: 999px;
      background: linear-gradient(135deg, rgba(255, 233, 133, 0.2) 0%, rgba(250, 116, 43, 0.2) 100%);
      opacity: 0.8;
    }
  }
  .item:nth-child(3){
    background: url(../assets/feature2@2x.png) center bottom no-repeat;
    background-size: cover;
    background-color: #fff;
    transition-delay: 0.6s;
    &::after{
      content:'';
      display: block;
      position: absolute;
      z-index: -1;
      width: 72px;
      height: 72px;
      right: -78px;
      top: 80px;
      border-radius: 999px;
      background: linear-gradient(135deg, rgba(255, 233, 133, 0.2) 0%, rgba(250, 116, 43, 0.2) 100%);
      opacity: 0.8;
    }
  }
  .item-title{
    /* 笔记功能 */
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 39px;
    color: #272727;
    text-align: center;
    padding-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    .item-icon{
      margin-right: 14px;
    }
  }
  .item-text{
    /* Github 提交图表，项目数量图表，Star趋势图表等 */
    font-size: 20px;
    line-height: 28px;
    color: #4a4a4a;
    padding: 43px 34px 0;
    text-align: center;
  }
}
.feature_active{
  .item{
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

//en
.home_en{
  .pic1 {
    .pic-title{
      width: 600px;
    }
  }
  .pic2{
    .pic-text{
      margin-right: 96px;
    }
    .pic-title{
      width: 446px;
    }
    .pic-des{
      width: 454px;
    }
  }
  .pic-title{
    width: 600px;
    line-height: 52px;
  }
  .pic-des{
    font-size: 20px;
  }
  .feature {
    .item-title{
      display: block;
      line-height: 32px;
    }
    .item-icon{
      display: block;
      margin: 0 auto 16px;
    }
    .item-title{
      height: 65px;
      padding-top: 32px;
      height: 105px;
      display: flex;
      flex-direction: column;
      align-content: center;
    }
    .item-text{
      text-align: center;
      font-size: 20px;
      padding-top: 24px;
    }
  }
}

.video_w{
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  right: 0;
  z-index: 100;
  background: rgba(0,0,0,0.8);
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease;
}
.video_w_show{
  visibility: visible;
  opacity: 1;
}
.video_w .video{
  width: 800px;
  height: 600px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
}
.video_w .close_w{
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background: rgba(255,255,255,0.18);
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
}
.video_w .close{
  display: block;
  width: 27px;
  height: 27px;
}

</style>
