import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/email',
    name: 'Email',
    component: () => import(/* webpackChunkName: "Email" */ '../views/Email.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "Privacy" */ '../views/Privacy.vue'),
  },
  {
    path: '/trending',
    name: 'Trending',
    component: () => import(/* webpackChunkName: "Trend" */ '../views/Trend.vue'),
  },
  {
    path: '/updates',
    name: 'Updates',
    component: () => import(/* webpackChunkName: "Update" */ '../views/Update.vue'),
  },
  {
    path: '/pricing',
    name: 'Price',
    component: () => import(/* webpackChunkName: "Price" */ '../views/Prices.vue'),
  },
  {
    path: '/termsofservice',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "TermsOfService" */ '../views/TermOfService.vue'),
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
