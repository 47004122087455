import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    lang: 'en',
  },
  mutations: {
    setUserData(state, user) {
      state.user = user
    },
    logOut(state) {
      state.user = null
      // window.localStorage.removeItem('user')
    },
    setLang(state, lang) {
      state.lang = lang
    },
  },
  actions: {
    getUserInfo({ commit }) {
      const http = this._vm.$http
      return http.get('/user/').then((res) => {
        if (res.user) {
          commit('setUserData', res.user)
          return res.user
        } else {
          return Promise.reject()
        }
      }).catch((err) => {
        return Promise.reject(err)
      })
    },
    logOut({ commit }) {
      const req = this._vm.$http
      return req.get('/auth/logout').then(() => {
        commit('logOut')
      }).catch(() => {
        commit('logOut')
      })
    },
  },
  modules: {
  },
})
