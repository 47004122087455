<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import '@/scss/base.scss'
import { mapState, mapActions, mapMutations } from 'vuex'

export default {
  mounted() {
    const lang = this.$route.query.lang || window.localStorage.getItem('lang') || navigator.language
    if (lang.indexOf('zh') !== -1) {
      this.$i18n.locale = 'zh'
    } else {
      this.$i18n.locale = 'en'
    }
    this.setLang(lang)
    this.getUserInfo()
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
  },
  methods: {
    ...mapMutations({
      setLang: 'setLang',
    }),
    ...mapActions({
      getUserInfo: 'getUserInfo',
    }),
  },
}

</script>
