export const ERROR = {
  FORMAT_INVALID: 'FORMAT_INVALID',
  DATA_NOT_FOUND: 'DATA_NOT_FOUND',
  DATA_EXISTED: 'DATA_EXISTED',
  DATA_INVALID: 'DATA_INVALID',
  LOGIN_REQUIRED: 'LOGIN_REQUIRED',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  PERMISSION_DENIED: 'PERMISSION_DENIED',
  TOO_MANY_REQUESTS: 'TOO_MANY_REQUESTS',
  TIME_OUT: 'TIME_OUT',
  SERVER_ERROR: 'SERVER_ERROR',
  ERROR_DEFAULT: 'ERROR_DEFAULT',
  NOT_PRO: 'NOT_PRO',
}

export const ERROR_MAP = {
  FORMAT_INVALID: {
    code: 1,
    msg: '数据格式不正确',
    msg_en: 'The request format is invalid',
  },
  DATA_INVALID: {
    code: 4,
    msg: '请求数据不支持',
    msg_en: 'The data is invalid',
  },
  DATA_NOT_FOUND: {
    code: 2,
    msg: '抱歉，数据已经不存在了',
    msg_en: 'The data is not found in database',
  },
  DATA_EXISTED: {
    code: 3,
    msg: '抱歉，该数据已存在',
    msg_en: 'The data has exist in database',
  },
  LOGIN_REQUIRED: {
    code: 5,
    msg: '登陆已过期',
    msg_en: 'Please login first',
  },
  TOKEN_EXPIRED: {
    code: 6,
    msg: '登陆已过期，即将跳转到登陆界面',
    msg_en: 'Token expired',
  },
  PERMISSION_DENIED: {
    code: 7,
    msg: '抱歉，你没有权限',
    msg_en: 'You have no permission to operate',
  },
  TOO_MANY_REQUESTS: {
    code: 7,
    msg: '访问太频繁，休息一下吧',
    msg_en: 'Too many request',
  },
  TIME_OUT: {
    code: 9,
    msg: '访问超时，请稍后尝试',
    msg_en: 'Time out',
  },
  SERVER_ERROR: {
    code: 999,
    msg: '服务器开小差了，请稍后尝试',
    msg_en: 'Internal Server Error',
  },
  ERROR_DEFAULT: {
    code: 110,
    msg: '服务器开小差了，请稍后尝试',
    msg_en: 'Internal Server Error',
  },
  NO_PRO: {
    code: 8,
    msg: '',
  },
}
