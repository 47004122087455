module.exports = {
  header: {
    doc: '文档',
    updates: '更新日志',
    trending: 'GitHub 趋势',
    prices: '价格',
    language: '语言',
  },
  banner: {
    extensionBtn: '下载浏览器插件',
    des: '结合浏览器插件，高效方便地管理你的Github Star',
  },
  des: {
    title1_1: '结合',
    title1_2: '插件',
    title1_3: '添加分类',
    des1_1: '浏览器插件让添加分类更加方便和高效，Github页面上点击Star按钮直接编辑分类，',
    des1_2: '省去繁琐的流程',
    title2_1: '管理',
    title2_2: '和',
    title2_3: '查找',
    title2_4: '',
    des2_1: '在App上管理你所有项目，快速',
    des2_2: '查找和定位',
    des2_3: '，不再为找不到项目而烦恼',
    title3_1: '快速',
    title3_2: '发现好项目',
    des3_1: 'Explore模块',
    des3_2: '让你方便地发现好项目，点击Star 按钮即可直接分类收藏，后期会加入更多探索功能',
    title4_1: '分享',
    title4_2: '你的分类',
    des4_1: '如果你整理了很棒的分类，可以将整个分类',
    des4_2: '分享',
    des4_3: '给朋友',
  },
  features: {
    title1: '笔记功能',
    des1: '优化过的阅读界面，记录你的每个项目的心得',
    title2: '文件列表',
    des2: '可以直接在LittleStar查看项目文件和代码，不用再跳转回Github',
    title3: '个性化数据展示',
    des3: 'Github 提交图表，项目数量图表，Star趋势图表等',
  },
  prices: {
    free: {
      1: 'Github Stars 项目管理',
      21: '创建',
      22: '10个',
      23: '项目分类',
      3: '分享分类集合',
      4: 'Github Trending',
      5: '使用Icon',
      6: '自动备份',
      7: '更多features',
      8: '笔记功能📒',
      9: '文件树🌲，代码阅读功能',
      10: '个性化数据展示',
    },
    premium: {
      1: 'Github Stars 项目管理',
      21: '创建',
      22: '无限',
      23: '项目分类',
      3: '分享分类集合',
      4: 'Github Trending',
      5: '使用Icon',
      6: '自动备份',
      7: '更多features',
      8: '笔记功能📒',
      9: '文件树🌲，代码阅读功能',
      10: '个性化数据展示',
    },
    off: '使用黑五促销代码6折',
    renewal: '自动续费',
    try: '试用看看',
    sub: '订阅',
    sub_tip: '国内用户仅支持信用卡支付',
    order_now: '购买',
  },
  footer: {
    subtitle: '高效方便地管理你的Github Star',
  },
  trend: {
    selectDatePlaceHolder: '请选择时间',
    selectLangPlaceHolder: '请选择语言',
    allLang: '所有语言',
    daily: '今日',
    weekly: '本周',
    monthly: '本月',
  },
  emailPanel: {
    editBtn: '修改邮件',
    placeholder: '请输入邮件地址',
    verifyBtn: '发送验证邮件',
    cancelBtn: '取消修改',
    varifyUpdate: '已验证，立即更新信息',
    updateSucc: '更新成功',
    sendTip: '已发送邮件至',
    reSendTip: '没收到？重新发送',
    emailVarifyErr: '邮件格式错误',
    emailReqSuccTip: '已发送验证邮件到您的邮箱',
    verifyTip: '请验证您的Email',
    verifyTip_2: '我们需要验证您的Email来完成您的账户初始化设置',
  },
}
