import Axios from 'axios'
import axiosCancel from 'axios-cancel'
// import qs from 'qs'
import { HttpRoot } from '@/config/'
import { ERROR_MAP, ERROR } from './error/ErrorMap'
import { getCookie } from '../util/func'

const user = window.localStorage.getItem('user') ? JSON.parse(window.localStorage.getItem('user')) : {}

const instance = Axios.create({
  baseURL: HttpRoot,
  withCredentials: true,
  timeout: 1000000,
})

axiosCancel(instance, {
  debug: false, // default
  reason: 'cancel',
})
export default async function (store, router, errorHandler) {
  try {
    const csrfToken = getCookie('_csrf_token')
    instance.defaults.headers.common['x-csrf-token'] = csrfToken
  } catch (e) {
    console.warn('no csrftoken')
  }

  const http = {
    get(url, data, options) {
      return instance.get(url, {
        params: data || null,
        ...options,
      })
    },
    delete(url, data, options) {
      return instance.delete(url, {
        data: data || null,
        ...options,
      })
    },
    put: (url, postData = {}, options = {}) => {
      return instance.put(url, postData, options)
    },
    post: (url, postData = {}, options = {}) => {
      return instance.post(url, postData, options)
    },
    cancel(reqId) {
      instance.cancel(reqId, 'cancel')
    },
    axios: instance,
  }

  instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    if (config.noCsrfToken) {
      config.headers = JSON.parse(JSON.stringify(config.headers, (key, value) => {
        if (key === 'x-csrf-token') {
          return undefined
        } else {
          return value
        }
      }))
    }

    return config
  }, function (error) {
    // Do something with request error
    return Promise.reject(error)
  })

  // 添加响应拦截器
  instance.interceptors.response.use((response) => {
    return response.data
  }, (error, config) => {
    const response = error && error.response
    if (error.message === 'cancel') {
      return Promise.reject(Object.assign({}, error, {
        msg: '请求取消',
      }))
    }
    /**
     * 登陆失败处理
     */
    if (response && response.status === 401) {
      console.log('status', response.status)
      /* 需要重新登录的情况 */
      if (response.data.type === ERROR.LOGIN_REQUIRED && user && user.refreshToken) {
        // 如果需要重新登录且本地存在refreshtoken，重新发送请求
        let reuestBody = {}
        if (error.config.data) {
          reuestBody = JSON.parse(error.config.data)
        }
        return http[error.config.method](error.config.url, {
          ...reuestBody,
        }, {
          ...error.config,
          params: {
            ...error.config.params,
            refreshToken: user.refreshToken,
          },
        })
      }
      /* refrestoken 以及权限不够的情况 */
      if (response.data.type === ERROR.TOKEN_EXPIRED ||
        response.data.type === ERROR.LOGIN_REQUIRED) {
        store.dispatch('user/logOut')
        if (router) router.replace({ name: 'login' })
        window.localStorage.removeItem('user')
        return
      }
    }
    if (response && response.status === 403 && response.data.type === ERROR.PERMISSION_DENIED) {
      router.replace({ name: 'login' })
      store.dispatch('user/logOut')
      window.localStorage.removeItem('user')
      return
    }

    // 错误信息处理
    let msg
    // 如果是pro升级的提示，则不走handlererrr
    if (response && response.status === 403 && response.data.type === ERROR.NOT_PRO) {
      store.commit('showUpgrade')
    } else {
      // 错误信息处理
      if (response && response.data && response.data.type) {
        if (response.data.detail && response.data.detail.msg) {
          msg = response.data.detail.msg
        } else {
          msg = (store.state.lang === 'en' ? ERROR_MAP[response.data.type].msg_en : ERROR_MAP[response.data.type].msg) ||
            response.data.msg
        }
      } else {
        msg = (store.state.lang === 'en' ? ERROR_MAP.ERROR_DEFAULT.msg_en : ERROR_MAP.ERROR_DEFAULT.msg)
      }
      if (errorHandler && !(error.config && error.config.noHandler)) {
        errorHandler(msg, response)
      }
    }

    return Promise.reject(Object.assign({}, error, {
      msg,
      status: response ? response.status : 500,
    }))
  })

  // 封装请求方法
  return http
}
