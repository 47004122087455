export const isProduction = process.env.NODE_ENV === 'production'
console.log(process.env.NODE_ENV)
export const HttpRoot = !isProduction ? '/' : '/'
export const Github = {
  authUrl: !isProduction ? '/auth/github/' : '/auth/github/',
}

export const sentryCSN = 'https://165d1566b968412faf95e3365fe0fddf@o201635.ingest.sentry.io/5471347'
export const ContactEmail = 'wzlrocker@gmail.com'
export const githubProxyHost = 'https://littlestarpxy.xyz'
