import { HttpRoot } from '@/config/'
/**
 * 对象克隆
 *
 * @export
 * @param {Object} obj 需要克隆的对象
 * @returns Object
 */
export function ObjectClone (obj) {
  const jsonStr = JSON.stringify(obj)

  return JSON.parse(jsonStr)
}

export function validObj (obj, keys, fnc = key => obj[key] !== '') {
  return keys.every(fnc)
}

/**
 * 更新时间
 * @param {*} date
 * @param {*} format
 * @returns
 */
export function updateTime (time) {
  const updateTime = new Date(time).getTime()
  return Math.floor((new Date().getTime() - updateTime) / 1000 / 60 / 60 / 24)
}

/**
 * 时间格式函数
 *
 * @export
 * @param {any} [date=new Date()]
 * @param {string} [format='YYYY-MM-DD']
 * @returns
 */

export function DateFormat (date = new Date(), format = 'YYYY-MM-DD') {
  if (!date || !(date instanceof Date)) {
    return ''
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const min = date.getMinutes()
  const sec = date.getSeconds()

  return format
    .replace('YYYY', year)
    .replace('MM', +month >= 10 ? month : `0${month}`)
    .replace('DD', +day >= 10 ? day : `0${day}`)
    .replace('HH', +hour >= 10 ? hour : `0${hour}`)
    .replace('mm', +min >= 10 ? min : `0${min}`)
    .replace('ss', +sec >= 10 ? sec : `0${sec}`)
}

/**
 * 获取时间上为00:00的日期
 *
 * @export
 * @param {Date} [date=new Date()] 需要获取的日期.默认为当前
 * @returns Date
 */
export function getConsistencyDate (date = new Date()) {
  return new Date(`${date.toLocaleDateString()} 00:00`)
}

/**
 * 获取今天是本年第几周
 * 以当年第一个周一作为本年第一周
 * @param {Date} 当前日期
 * @returns Number 周数
 */
export function getWeek (currentDate) {
  // 一年第一个周一算第一周的开始
  // const tmpDate = new Date(currentDate.toLocaleDateString() + ' 00:00');
  const tmpDate = getConsistencyDate(currentDate)
  let tmpDay = tmpDate.getDay()
  tmpDay = tmpDay === 0 ? 7 : tmpDay // 获取星期几
  tmpDate.setDate(tmpDate.getDate() - tmpDay + 1) // 将其调整为当周星期一

  const startDate = new Date(tmpDate.getFullYear(), 0, 1) // 获取当年第一天
  let startWeekDay = startDate.getDay()
  startWeekDay = startWeekDay === 0 ? 7 : startWeekDay
  if (startWeekDay !== 1) {
    // 如果当天不是周期,则获取当年第一个周一的日期
    startDate.setDate(startDate.getDate() + 8 - startWeekDay)
  }
  const offset = (tmpDate - startDate) / 60 / 60 / 24 / 1000 // 计算时间差
  return Math.ceil(offset / 7) + 1
}
/**
 * 获取今天是本年第几周
 * 以当年01-01作为本年第一周
 * @param {Date} 当前日期
 * @returns Number 周数
 */
export function getWeekInYear (currentDate) {
  // 一年的第一天算是当年第一周的开始
  // const tmpDate = new Date(currentDate.toLocaleDateString() + ' 00:00'),
  const tmpDate = getConsistencyDate(currentDate)
  const startDate = new Date(currentDate.getFullYear(), 0, 1) // 获取当年第一天
  let tmpDay = tmpDate.getDay() // 获取星期几
  let startWeekDay = startDate.getDay() // 获取第一题星期几
  let offset = (tmpDate - startDate) / 60 / 60 / 24 / 1000 // 计算两天的日期差
  startWeekDay = startWeekDay === 0 ? 7 : startWeekDay
  tmpDay = tmpDay === 0 ? 7 : tmpDay
  offset -= tmpDay - startWeekDay // 根据两天星期几之差修正offset.保证两天是在一周中是同一天
  const result = Math.ceil(offset / 7) + 1

  return result
}

/**
 * 以当前日期切换上下周.每次+-7天;
 * @export
 * @param {Date} currentDate 当前日期
 * @param {String} type prev|next
 * @returns {Date}
 */
export function switchSiblingWeek (currentDate, type) {
  const date = currentDate.getDate()
  const tmpDate = new Date(currentDate)
  if (type === 'prev') {
    tmpDate.setDate(date - 7)
  } else {
    tmpDate.setDate(date + 7)
  }
  return tmpDate
}

/**
 * 以当前日期切换周期.如果切换后日期不包括01-01或者12-31,则+- 7天
 * 否则调整为01-01或者12-31
 * 如果当前日期为01-01,则切换后为12-31
 * 如果当前日期为12-31,则向下切换后为01-01
 * 保证切换后在当年
 * @export
 * @param {Date} currentDate 当前日期
 * @param {String} type prev|next
 * @returns {Date}
 */
export function switchSiblingWeekInYear (currentDate, type) {
  const date = currentDate.getDate()
  const tmpDate = new Date(currentDate)
  const fullYear = tmpDate.getFullYear()
  const month = tmpDate.getMonth()
  let result
  if (type === 'prev') {
    tmpDate.setDate(date - 7)
    if (tmpDate.getFullYear() === fullYear) {
      result = tmpDate
    } else if (month === 0 && date === 1) {
      result = new Date(fullYear - 1, 11, 31) // 如果是1月1日.则转到上一年12-31
    } else {
      result = new Date(fullYear, 0, 1) // 如果是上一星期不在当年,则切换到当年的1-1
    }
  } else {
    tmpDate.setDate(date + 7)
    if (tmpDate.getFullYear() === fullYear) {
      result = tmpDate
    } else if (month === 11 && date === 31) {
      result = new Date(fullYear + 1, 0, 1) // 如果是12-31 则切到下年1月1
    } else {
      result = new Date(fullYear, 11, 31) // 如果下星期不在当年,则切换到当年12-31
    }
  }
  return result
}

/**
 * 返回本周的日期
 *
 * @export
 * @param {Date} currentDate 当前日期
 * @param {String} format 格式化
 * @returns Array
 */
export function getWeekDate (currentDate, format) {
  let weekDay = currentDate.getDay()
  weekDay = weekDay === 0 ? 7 : weekDay
  const result = []
  let date
  for (let i = 0; i < 7; i++) {
    date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + i - weekDay + 1,
    )
    // if(date.getFullYear() === currentDate.getFullYear()) {
    result.push(DateFormat(date, format))
    // }
  }

  return result
}

/**
 * 添加一个a标签用于下载
 *
 * @export
 * @param {String} url 地址
 */
export function download (url, fullLink) {
  const a = document.createElement('a')
  a.href = fullLink ? url : HttpRoot + url
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    document.body.removeChild(a)
  }, 500)
}

/**
 * xss过滤
 *
 * @export
 * @param {String} text 文本
 * @returns String
 */
export function xssFilter (text) {
  return !text ? '' : text
    .replace(/&/g, '&amp;')
    .replace(/"/g, '&quot;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/‘/g, '&#x27;')
    .replace(/\//g, '&#x2f')
}

export function calcWidth (element, hasTrend = false) {
  let dom = document.querySelectorAll(element)
  dom = Array.prototype.slice.call(dom, 0)
  let maxWidth = 0
  dom.forEach((each) => {
    let offsetWidth = each.offsetWidth
    if (hasTrend) {
      const trend = each.querySelector('.trend')
      offsetWidth += trend ? trend.offsetWidth * 2 + 14 : 0
    }
    if (offsetWidth > maxWidth) {
      maxWidth = offsetWidth
    }
  })
  dom.forEach((each) => {
    each.parentNode.style.width = `${maxWidth}px`
  })
}

/**
 * a,b直接绝对差
 *
 * @export
 * @param {Number} a
 * @param {Number} b
 * @returns Number
 */
export function gap (a, b) {
  return b === -1 ? '' : Math.abs(a - b)
}

export function activeTrend (newVal, oldVal) {
  const result = oldVal - newVal
  return result === 0 ? '' : result > 0 ? 'down' : 'up'
}
export function rankTrean (newVal, oldVal) {
  const result = oldVal - newVal
  if (oldVal === -1) {
    return 'new'
  }
  return result === 0 ? 'trend-line' : result > 0 ? 'up' : 'down'
}

/**
 * 数字处理，大于100000成立成 '10W+'，每3位数加逗号
 */
export function parseNum (num) {
  return !num ? 0 : num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
}

/**
 * 时间处理
 */
export function parseTime (val) {
  return val.replace(/:\d{2}$/, '')
}

/**
 * 获取scrolltop
 */
export function getScrollTop () {
  return document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop
}

/**
 * 获取scrollleft
 */
export function getScrollLeft () {
  return document.documentElement.scrollLeft || window.pageXOffset || document.body.scrollLeft
}

/**
 * 获取clientHeight
 */
export function getClientHeight () {
  return window.innerHeight || document.body.clientHeight || document.documentElement.clientHeight
}

/**
 * 获取clientWidth
 */
export function getClientWidth () {
  return window.innerWidth || document.body.clientWidth || document.documentElement.clientWidth
}

/**
 * 获取元素offsetTop
 */
export function getOffSetTop (elem) {
  let offTop = 0
  while (elem) {
    offTop += elem.offsetTop // 获取元素到其上一级元素顶部的距离
    elem = elem.offsetParent // 元素的上一级元素
  }
  return offTop
}

/* 设置scrollTop */
export function setScrollTop (top) {
  if (window.scroll) {
    window.scroll(0, top + 'px')
  } else if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = top + 'px'
  } else {
    document.body.scrollTop = top + 'px'
  }
}

/* 倒计时 */
export function getRemainTime (endTime) {
  const t = endTime - (new Date()).getTime()
  const seconds = Math.floor((t / 1000) % 60)
  const minutes = Math.floor((t / 1000 / 60) % 60)
  const hours = Math.floor((t / (1000 * 60 * 60)) % 24)
  const days = Math.floor(t / (1000 * 60 * 60 * 24))
  return {
    total: t,
    days,
    hours,
    minutes,
    seconds,
  }
}

/* 获取cookie */
export function getCookie (objName) { // 获取指定名称的cookie的值
  const arrStr = document.cookie.split('; ')
  for (let i = 0; i < arrStr.length; i++) {
    const temp = arrStr[i].split('=')
    if (temp[0] === objName) return temp[1]
  }
}

/* 设置cookie */
export function setCookie ({
  name, value, hours, domain, path,
}) { // 添加cookie
  let str = name + '=' + escape(value)
  if (hours !== undefined) { // 为0时不设定过期时间，浏览器关闭时cookie自动消失
    const date = new Date()
    const ms = hours * 3600 * 1000
    date.setTime(date.getTime() + ms)
    str += '; expires=' + date.toGMTString()
  }
  if (domain) {
    str += '; domain=' + domain
  }
  if (path) {
    str += '; path=' + path
  }
  document.cookie = str
}

/**
 * 全屏
 */
export function toggleFullScreen (e, isFullScreen) {
  if (e.type === 'keydown' && e.keyCode !== 13) return
  if (!isFullScreen) {
    // current working methods
    // if (document.documentElement.requestFullscreen) {
    //   document.documentElement.requestFullscreen()
    // } else if (document.documentElement.msRequestFullscreen) {
    //   document.documentElement.msRequestFullscreen()
    // } else if (document.documentElement.mozRequestFullScreen) {
    //   document.documentElement.mozRequestFullScreen()
    // } else if (document.documentElement.webkitRequestFullscreen) {
    //   document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    // }
    const el = document.documentElement
    const rfs = el.requestFullScreen || el.webkitRequestFullScreen ||
                el.mozRequestFullScreen || el.msRequestFullScreen
    if (typeof rfs !== 'undefined' && rfs) {
      rfs.call(el)
    } else if (typeof window.ActiveXObject !== 'undefined') {
      // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
      /* global ActiveXObject */
      const wscript = new ActiveXObject('WScript.Shell')
      wscript.SendKeys('{F11}')
    }
    isFullScreen = true
  } else {
    const el = document
    const cfs = el.cancelFullScreen || el.webkitCancelFullScreen ||
                el.mozCancelFullScreen || el.exitFullScreen
    if (typeof cfs !== 'undefined' && cfs) {
      cfs.call(el)
    } else if (typeof window.ActiveXObject !== 'undefined') {
      // for IE，这里和fullScreen相同，模拟按下F11键退出全屏
      const wscript = new ActiveXObject('WScript.Shell')
      wscript.SendKeys('{F11}')
    }
    isFullScreen = false
  }
}

/**
 * 排名补0
 */
export function parseRank (val) {
  return parseInt(val, 10) < 10 ? '0' + val : val
}

/**
 * 高亮
 */
export function heightLight (val, keyword) {
  if (!keyword) return val
  const keywordList = keyword.replace(/(^\s+)|(\s+$)/g, '').split(/\s+/g)
  let title = xssFilter(val)
  title = title.replace(new RegExp(`(${keywordList.join('|')})`, 'gi'), '<mark class="g_hightlight">$1</mark>')
  return title
}

/**
 * 计算持续时长
 * @param {String} start 开始时间戳
 * @param {String} end 结束时间戳
 * @param {Number} dateDiffTime 持续时间，毫秒
 * @returns String 时长（x小时x分钟/x天x小时）
 */
export function parseDeltaTime ({ start, end, dateDiffTime }) {
  let dateDiff = 0

  if (!dateDiffTime && dateDiffTime !== 0) {
    if (!start) return '-'

    const startTime = new Date(start.replace(/-/g, '/'))
    const endTime = end ? new Date(end.replace(/-/g, '/')) : new Date()
    dateDiff = endTime.getTime() - startTime.getTime() // 时间差的毫秒数
  } else {
    dateDiff = dateDiffTime // 相差的毫秒数
  }

  let dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) // 计算出相差天数
  const leave1 = dateDiff % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000)) // 计算出小时数
  // 计算相差分钟数
  const leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000)) // 计算相差分钟数
  // 计算相差秒数
  const seconds = parseInt(leave2 / 1000, 10) // 计算小时数后剩余的毫秒数

  if (hours >= 24) {
    dayDiff += 1
    hours -= 24
  }
  let result = ''
  if (dayDiff > 0) {
    result += dayDiff + '天'
  }
  if (hours > 0) {
    result += hours + '小时'
  }
  if (dayDiff <= 0 && minutes > 0) {
    result += minutes + '分钟'
  }
  if (dayDiff <= 0 && minutes <= 0 && seconds > 0) {
    result += seconds + '秒'
  }
  if (!result) {
    result = '0小时'
  }

  return result
}
